import React, { useState } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import logo from './logo192.png';
import { ThreeDots } from 'react-loader-spinner';

function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // State to handle loading

  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Password strength validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setMessage('Failed: Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number');
      return;
    }

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setMessage('Failed: Passwords do not match');
      return;
    }

    try {
      setLoading(true); // Start the loader
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
      const response = await axios.post(`${backendUrl}/reset-password/${token}`, { new_password: newPassword });
      setMessage(response.data.message);
      if (response.status === 200) {
        // Redirect to the login page after successful password reset
        setTimeout(() => {
          navigate('/login');
        }, 2000); // Redirect after 2 seconds to give time to display the success message
      }
    } catch (error) {
      setMessage('Password reset failed');
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <img src={logo} alt="CUNY Course Sniper Logo" style={styles.logo} /> {/* Logo added here */}
        <h3 style={styles.subtitle}>Reset Password</h3>
        <form onSubmit={handleResetPassword}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>New Password:</label>
            <input
              type={showPassword ? 'text' : 'password'} // Toggle between text and password
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Confirm Password:</label>
            <input
              type={showPassword ? 'text' : 'password'} // Toggle between text and password
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                style={styles.checkbox}
              />
              Show Password
            </label>
          </div>
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? "Resetting Password..." : "Reset Password"}
          </button>
          {loading && (
            <div style={styles.loaderContainer}>
              <ThreeDots color="#007bff" height={40} width={40} />
            </div>
          )}
        </form>
        <p className="link" style={{ textAlign: 'center', marginTop: '10px' }}>
        Remembered your password? <Link to="/login">Login</Link>
        </p>
        {message && <p style={message.includes('Failed') ? styles.errorMessage : styles.successMessage}>{message}</p>}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#fff',
  },
  box: {
    maxWidth: '400px',
    width: '100%',
    padding: '35px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '275px', // Increase the size of the logo
    marginBottom: '5px', // Add more space below the logo to lower it
  },
  subtitle: {
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '20px', // Adjusted the margin for better spacing
    fontSize: '24px',  // Increased font size
    fontWeight: 'bold', // Made the subtitle bold
    color: '#333',  // Set a dark grey color for a more subtle look
  },
  inputGroup: {
    marginBottom: '15px',
    textAlign: 'center', // Centering the input group
  },
  label: {
    display: 'block',
    textAlign: 'center !important',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  checkbox: {
    marginBottom: '4px',
    marginRight: '8px',
    transform: 'scale(1.5)',  // Optional: Increase the size of the checkbox for better alignment
  },
  button: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
  },
  errorMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'red',
  },
  successMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'green',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
};

export default ResetPassword;
