import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ProfileContext } from './ProfileContext';
import { ThreeDots } from 'react-loader-spinner';
import './Profile.css';

const colleges = [
  'Baruch College', 'Borough of Manhattan CC', 'Bronx CC', 'Brooklyn College', 'City College',
  'College of Staten Island', 'Graduate Center', 'Guttman CC', 'Hostos CC', 'Hunter College',
  'John Jay College', 'Kingsborough CC', 'LaGuardia CC', 'Lehman College', 'Macaulay Honors College',
  'Medgar Evers College', 'NYC College of Technology', 'Queens College', 'Queensborough CC',
  'School of Journalism', 'School of Labor & Urban Studies', 'School of Law', 'School of Medicine',
  'School of Professional Studies', 'School of Public Health', 'York College'
];

const studentTypes = [
  'Doctoral', 'Graduate', 'Graduate School Graduate', 'Law', 'Medical', 'Undergraduate'
];

function Profile() {
  // const [profile, setProfile] = useState({
  //   name: '',
  //   college: '',
  //   student_type: '',
  //   cuny_username: '',
  //   cuny_password: '',
  //   login: ''
  // });
  const { updateProfileLocally, profile = {}, setProfile } = useContext(ProfileContext); // Get fetchProfile from context
  const [loading, setLoading] = useState(false); // Add this line
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [actualPassword, setActualPassword] = useState('');  // To store the actual password
  // const [inputType, setInputType] = useState('password');  // To control the input type (password or text)
    

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
        const response = await axios.get(`${backendUrl}/profile`, { withCredentials: true });
  
        // Set the profile data, including the masked password
        setProfile(response.data);
  
        // If the backend sends a password, set it in the actualPassword state
        if (response.data.cuny_password) {
          setActualPassword(response.data.cuny_password);
        }
      } catch (error) {
        setMessage(error.response.data.message || 'Failed to fetch profile');
        if (error.response.status === 401) {
          navigate('/login');
        }
      }
    };
  
    fetchProfile();
  }, [navigate]);
  

  const handleLogout = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
      await axios.post(`${backendUrl}/logout`, {}, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      setMessage('Logout failed');
    }
  };

  const handleClickSchedule = (e) => {
    e.preventDefault();
    navigate('/mySchedule');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'cuny_password') {
      setActualPassword(value);  // Directly update the password without masking
    } else {
      setProfile({
        ...profile,
        [name]: value,
      });
    }
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(false); // Clear the message
    setLoading(true); // Start loading
  
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  
      // Only append @login.cuny.edu if the username is not empty
      const usernameWithDomain = profile.cuny_username && !profile.cuny_username.includes('@login.cuny.edu') 
          ? `${profile.cuny_username}@login.cuny.edu` 
          : profile.cuny_username;
  
      // Send the update request to the backend
      const response = await axios.post(`${backendUrl}/profile`, {
        ...profile,
        cuny_username: usernameWithDomain || profile.cuny_username,  // Send the username
        cuny_password: actualPassword,  // Send the real password
      }, { withCredentials: true });
    
      setMessage(response.data.message);
  
      if (response.data.message.includes('successfully')) {
        // Update the ProfileContext with the latest data
        updateProfileLocally({ 
          ...profile, 
          cuny_username: usernameWithDomain, 
          cuny_password: actualPassword  // Store the updated password
        });
        
        navigate('/homepage');  // Optionally navigate the user after success
      }
    } catch (error) {
        if (error.response && error.response.status === 400) {
          setMessage(error.response.data.error || 'CUNY login verification failed. Please check your credentials and try again.');
        } else {
          setMessage('Profile update failed.');
        }
    } finally {
      setLoading(false); // Stop loading
    }
  };
        
  return (
    <div className="profile-update-container">
      <nav className="profile-update-nav">
        <button className="profile-update-back-button" onClick={() => navigate('/homepage')}>Go Back</button>
  
        {/* My Account dropdown */}
        <div className="profile-accountDropdownContainer">
          <button className="profile-update-navButtonRight" onClick={() => setShowDropdown(!showDropdown)}>
            My Account
          </button>
          {showDropdown && (
            <div className="profile-dropdown">
              <button className="profile-dropdownButton" onClick={() => navigate('/mySchedule')}>
                Schedule
              </button>
              <button className="profile-dropdownButton" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
      </nav>
      <h2 className="profile-update-title">Profile</h2>
      {profile && (
        <form className="profile-update-form" onSubmit={handleSubmit}>
          <div className="profile-update-form-group">
            <label>College:</label>
            <select name="college" value={profile.college || ""} onChange={handleChange}>
              <option value=""></option>
              {colleges.map(college => (
                <option key={college} value={college}>{college}</option>
              ))}
            </select>
          </div>
          <div className="profile-update-form-group">
            <label>Student Type:</label>
            <select name="student_type" value={profile.student_type || ""} onChange={handleChange}>
              <option value=""></option>
              {studentTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div className="profile-update-form-group">
            <label>Name:</label>
            <input 
              type="text" 
              name="name" 
              value={profile.name || ""} 
              onChange={handleChange} 
              placeholder="Enter your name"
            />
          </div>
          <div className="profile-update-form-group cuny-username-group">
            <label>CUNY Username:</label>
            <div className="profile-update-username-input-container">
              <input
                type="text"
                name="cuny_username"
                value={profile.cuny_username || ""}
                onChange={handleChange}
                placeholder="Enter your username"
              />
              <span className="profile-update-username-domain">@login.cuny.edu</span>
            </div>
            <div className="clear-button-container">
              <button 
                type="button" 
                className="clear-field-button" 
                onClick={() => setProfile({ ...profile, cuny_username: '' })}
              >
                Clear Username
              </button>
              <p className="profile-update-cuny-note">
                *Updating your CUNY Credentials may take longer due to verification. <strong>DON'T RELOAD THE PAGE</strong>*
              </p>
            </div>
          </div>
  
          <div className="profile-update-form-group">
            <label>CUNY Password:</label>
            <input
              type="password"
              name="cuny_password"
              value={actualPassword || ""}
              onChange={handleChange}
              placeholder="Enter your CUNYfirst password"
            />
            <div className="clear-button-container">
              <button 
                type="button" 
                className="clear-field-button" 
                onClick={() => setActualPassword('')}
              >
                Clear Password
              </button>
            </div>
          </div>
          <button type="submit" className="profile-update-button" disabled={loading}>
            {loading ? "Updating..." : "Update Profile"}
          </button>
          {message && (
            <p style={!message.includes('successfully') ? styles.errorMessage : styles.successMessage}>
              {message}
            </p>
          )}
          {loading && (
            <div className="profile-update-loaderContainer">
              <ThreeDots color="#007bff" height={40} width={40} />
            </div>
          )}
        </form>
      )}
    </div>
  )};
  

export default Profile;

const styles = {
  // nav: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  //   width: '98%',
  //   position: 'absolute',
  //   top: '10px',
  //   left: '8px',
  //   right: '20px',
  //   paddingLeft: '0px',
  // },
  
  errorMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'red',
  },
  successMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'green',
  },
};