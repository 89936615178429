import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { Navigate, useParams, useNavigate, Link } from 'react-router-dom';
import { ProfileContext } from './ProfileContext';
import { ThreeDots } from 'react-loader-spinner';
import { FaTrash } from 'react-icons/fa';
import AddIcon from '@mui/icons-material/Add';
import './SearchPage.css'; 
import search_logo from './logo192.png';

const colorPalette = ['#ececec', // Very light gray, almost white
  '#9fd3c7', // Light teal or aqua (mix of green and blue tones)
  '#385170', // Muted dark blue with slight grayish tones
  '#142d4c' // Very dark blue, almost navy or midnight blue
];

function stringToColor(str) {
  if (!str) {
    return colorPalette[0];
  }

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash % colorPalette.length);
  return colorPalette[index];
}

const collegesData = [
  // College data as before
];

const terms = [
  { name: 'Fall 2024', slug: '2024-Fall-Term' },
  { name: 'Summer 2024', slug: '2024-Summer-Term' },
];

function SearchPage() {
  const {profile, updateProfileLocally, fetchProfile} = useContext(ProfileContext); // Access profile data from context

  const [searchTerm, setSearchTerm] = useState('');
  const [classData, setClassData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { isAuthenticated } = useContext(AuthContext);
  const { collegeSlug, termSlug } = useParams();
  const [college, setCollege] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNavDropdown, setShowNavDropdown] = useState(false);
  const navigate = useNavigate();


  const [expandedClasses, setExpandedClasses] = useState({});
  const [loadingClass, setLoadingClass] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [pendingAddClass, setPendingAddClass] = useState(null); // New state to track class being added
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingClasses, setIsLoadingClasses] = useState(true); // Manage loading state

  const handleLogout = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
      await axios.post(`${backendUrl}/logout`, {}, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      // setMessage('Logout failed');
    }
  };

  // const handleBack = () => {
  //   // Navigate back to the term selection page
  //   navigate(`/termSelection/${collegeSlug}`);
  // };
  useEffect(() => {
  console.log(profile);
  
  const selectedCollege = collegesData.find(col => 
    col.name.replace(/\s+/g, '-').toLowerCase() === collegeSlug.toLowerCase()
  );
  setCollege(selectedCollege || {});

  const fetchClassesFromBackend = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
      const response = await axios.get(`${backendUrl}/load_classes`, {
        params: { college: collegeSlug, term: termSlug }, // Pass the slug params
        withCredentials: true,
      });
  
      if (response.data.message === 'Class data loaded successfully') {
        const classes = response.data.classes;
        const untrackedClasses = classes.filter(cls =>
          !profile.tracked_classes.some(trackedClass => trackedClass.class_number === cls.class_number)
        );
        
        const sortedUntrackedClasses = untrackedClasses.sort((a, b) => {
          if (a.class_code && b.class_code) {
            return a.class_code.localeCompare(b.class_code);  // Ensure class_code exists and sort
          }
          return 0;  // Return 0 for any undefined class_code, keeping them in place
        });
        
        setClassData(untrackedClasses);
        setFilteredData(sortedUntrackedClasses);
        setIsLoadingClasses(false);
      } else {
        console.error('No class data found or still loading.');
        setIsLoadingClasses(false);
      }
    } catch (error) {
      console.error('Error fetching class data:', error);
      setIsLoadingClasses(false);
    }
  };
    
  fetchClassesFromBackend();
}, [collegeSlug, termSlug, profile.tracked_classes]);

  // useEffect(() => {
  //   setFilteredData(profile.tracked_classes || []); // Fallback to empty array if tracked_classes is undefined
  // }, [profile.tracked_classes]);

  // useEffect(() => {
  //   console.log(profile);
  //   const selectedCollege = collegesData.find(col => 
  //     col.name.replace(/\s+/g, '-').toLowerCase() === collegeSlug.toLowerCase()
  //   );
  //   setCollege(selectedCollege || {});

  //   const fetchProfileAndClasses = async () => {
  //     try {
  //       const selectedCollege = collegesData.find(col => 
  //         col.name.replace(/\s+/g, '-').toLowerCase() === collegeSlug.toLowerCase()
  //       );
  //       setCollege(selectedCollege || {});
  
  //       const college = collegeSlug.replace(/-/g, ' '); // Replace hyphens with spaces
  //       const term = termSlug.replace(/-/g, ' '); // Replace hyphens with spaces
  //       const classes = profile.all_classes[college][term]; // Access the appropriate part of the dictionary
  
  //       // Filter out classes that are already being tracked
  //       const untrackedClasses = classes.filter(cls => 
  //         !profile.tracked_classes.some(trackedClass => trackedClass.class_number === cls.class_number)
  //       );
  
  //       setClassData(untrackedClasses);
  //       setFilteredData(untrackedClasses);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  
  //   fetchProfileAndClasses();
  // }, [collegeSlug, termSlug, profile.tracked_classes]);


  const handleRemoveClass = async (cls) => {
    try {
        setLoadingClass(cls.class_number); // Set the loading state to the current class number

        // Create a new array of tracked classes without the removed class
        const updatedTrackedClasses = profile.tracked_classes.filter(
            trackedClass => trackedClass.class_number !== cls.class_number
        );

        // Update the profile locally to remove the class visually
        updateProfileLocally({ tracked_classes: updatedTrackedClasses });
        

        // Also update the local state for classData and filteredData to remove the class visually
        setClassData(prevClassData => prevClassData.filter(
            classItem => classItem.class_number !== cls.class_number
        ));
        setFilteredData(prevFilteredData => prevFilteredData.filter(
            classItem => classItem.class_number !== cls.class_number
        ));

        // Make the API call to remove the class from the backend
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
        const response = await axios.post(`${backendUrl}/remove_class`, { cls }, { withCredentials: true });

        if (response.data !== 'success') {
            console.error('Failed to remove class from the backend');
            // Optionally, revert the changes if the backend fails
            updateProfileLocally(prevProfile => ({
                ...prevProfile,
                tracked_classes: [...prevProfile.tracked_classes, cls]
            }));
            setClassData(prevClassData => [...prevClassData, cls]);
            setFilteredData(prevFilteredData => [...prevFilteredData, cls]);
        }
    } catch (error) {
        console.log('Remove class failed', error);
        // Optionally, revert the changes in case of an error
        updateProfileLocally(prevProfile => ({
            ...prevProfile,
            tracked_classes: [...prevProfile.tracked_classes, cls]
        }));
        setClassData(prevClassData => [...prevClassData, cls]);
        setFilteredData(prevFilteredData => [...prevFilteredData, cls]);
    } finally {
        setLoadingClass(null); // Reset the loading state
    }
};

// Example useEffect to log the profile.tracked_classes when it changes
// useEffect(() => {
//     console.log('Tracked Classes updated:', profile.tracked_classes);
// }, [profile.tracked_classes]);

// useEffect(() => {
//     console.log('Class Data updated:', classData);
// }, [classData]);

// useEffect(() => {
//     console.log('Filtered Data updated:', filteredData);
// }, [filteredData]);


const handleSearch = async (e) => {
  setSearchTerm(e.target.value);
  setSearchLoading(true);  // Start loading
  console.log("Search started, loading:", searchLoading);

  try {
    const filtered = classData.filter((cls) => {
      return (
        (cls.class_number && cls.class_number.includes(e.target.value)) ||
        (cls.class_code && cls.class_code.toLowerCase().includes(e.target.value.toLowerCase())) ||
        (cls.instructor && cls.instructor.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    });

    setFilteredData(filtered);
  } catch (error) {
    console.error('Error during search:', error);
  } finally {
    setSearchLoading(false);  // Stop loading
    console.log("Search ended, loading:", searchLoading);
  }
};


const handleAddClass = async (classToAdd) => {
  try {
    setLoadingClass(classToAdd.class_number);
    setPendingAddClass(classToAdd.class_number); // Set pending class being added

    let maxClasses = 5; 
    switch (profile.membership_tier) {
      case 'Free':
        maxClasses = 5;
        break;
      case 'Silver':
        maxClasses = 10;
        break;
      case 'Gold':
        maxClasses = 15;
        break;
      case 'Diamond':
        maxClasses = 20;
        break;
      case 'Developer':
        maxClasses = 100;
        break;
      default:
        maxClasses = 5;
    }

    if (profile.tracked_classes.length >= maxClasses) {
      setErrorMessage(`You can only track up to ${maxClasses} classes in the ${profile.membership_tier} tier.`);
    } else {
      setErrorMessage(''); 
      
      // Optimistically update the tracked classes in the UI
      const updatedTrackedClasses = [...profile.tracked_classes, classToAdd];
      updateProfileLocally({ tracked_classes: updatedTrackedClasses });
      
      // Immediately remove the added class from search results
      setClassData(prevClassData => prevClassData.filter(cls => cls.class_number !== classToAdd.class_number));
      setFilteredData(prevFilteredData => prevFilteredData.filter(cls => cls.class_number !== classToAdd.class_number));

      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
      const response = await axios.post(`${backendUrl}/add_class`, { cls: classToAdd }, { withCredentials: true });

      if (response.data !== 'success') {
        console.error('Failed to add class on the backend.');
        
        // Revert the changes if the backend fails
        updateProfileLocally(prevProfile => ({
          ...prevProfile,
          tracked_classes: prevProfile.tracked_classes.filter(cls => cls.class_number !== classToAdd.class_number)
        }));
        setClassData(prevClassData => [...prevClassData, classToAdd]);
        setFilteredData(prevFilteredData => [...prevFilteredData, classToAdd]);
      } else {
        // Optionally refetch profile if needed for other parts of the state
        await fetchProfile(); // Ensure the profile is up to date
      }
    }
  } catch (error) {
    console.error('Error adding class:', error);

    // Revert the UI changes in case of an error
    updateProfileLocally(prevProfile => ({
      ...prevProfile,
      tracked_classes: prevProfile.tracked_classes.filter(cls => cls.class_number !== classToAdd.class_number)
    }));
    setClassData(prevClassData => [...prevClassData, classToAdd]);
    setFilteredData(prevFilteredData => [...prevFilteredData, classToAdd]);
  } finally {
    setLoadingClass(null);
    setPendingAddClass(null); // Clear the pending state
  }
};


  const handleTermChange = (newTermSlug) => {
    navigate(`/searchPage/${collegeSlug}/${newTermSlug}`);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleClassDetails = (classNumber) => {
    setExpandedClasses(prevState => ({
      ...prevState,
      [classNumber]: !prevState[classNumber]
    }));
  };
  

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Loading indicator if classes are still loading
  if (isLoadingClasses) {
    return (
      <div className="classsearch-page-loading-container">
        <ThreeDots
          color = '#9fd3c7'
          ariaLabel="three-dots-loading"
          visible={true}
        />
        <p>Loading classes, please wait...</p>
      </div>
    );
  }
  
  return (
    <div className="classsearch-pageContainer" style={{ backgroundColor: '#ececec' }}>
        {/*Search bar container with logo*/}
        <div className="classsearch-searchBox">
        <Link to="/homepage">
          <img src={search_logo} alt="CUNY Course Sniper Logo" className="classsearch-logo" />
        </Link>
            <input
                type="text"
                placeholder="Search by Class Code, Number, or Instructor"
                value={searchTerm}
                onChange={handleSearch}
                className="input"
                onFocus={() => setShowDropdown(true)}
                onBlur={() => {
                  if (!loadingClass) { // Only close dropdown if no class is being added
                    setTimeout(() => setShowDropdown(false), 200);
                  }
                }}            />
              {/* Account Dropdown*/}
            <div className="classsearch-accountNavDropdownContainer">
              <button 
                className="classsearch-navDropdownButton"
                onClick={() => setShowNavDropdown(!showNavDropdown)} // Use the correct state variable
              >
                My Account
              </button>
              {showNavDropdown && ( // Use the correct state variable here
                <div className="classsearch-navDropdownContent">
                  <button 
                    className="classsearch-navDropdownButtonItem"
                    onClick={() => navigate('/profile')}
                  >
                    Profile
                  </button>
                  <button 
                    className="classsearch-navDropdownButtonItem"
                    onClick={() => navigate('/mySchedule')}
                  >
                    Schedule
                  </button>
                  <button 
                    className="classsearch-navDropdownButtonItem"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
                 
            {/* <button className="classsearch-backButton" onClick={handleBack}>
              Back
            </button> */}
             {/* Loading indicator */}
             {searchLoading && (
                <div className="classsearch-loadingIndicator">
                  <ThreeDots
                    height="20"
                    width="60"
                    radius="9"
                    color="#007bff"
                    ariaLabel="three-dots-loading"
                    visible={true}
                  />
                </div>
              )}
            {showDropdown && (
                      <div className="classsearch-dropdownContentsearchbar">
                          <ul>
                            {filteredData.map((cls, index) => (
                              <li 
                                key={index} 
                                className="classsearch-classItem" 
                                onClick={() => handleAddClass(cls)}
                                style={{ backgroundColor: stringToColor(college.color, cls.class_code) }}
                              >
                                {cls.class_code}, {cls.class_number}, {cls.instructor}
                                {/* Additional info to show on hover */}
                                <div className="hover-info">
                                    {/* <strong>Class Info:</strong> {cls.class_description || "No description available"} */}
                                    <div><strong>Class Code:</strong> {cls.class_code}</div>
                                        <div><strong>Course Topic:</strong> {cls.course_topic}</div>
                                        <div><strong>Class Component:</strong> {cls.class_component}</div>
                                        <div><strong>Instructor:</strong> {cls.instructor}</div>
                                        <div><strong>Class Number:</strong> {cls.class_number}</div>
                                        <div><strong>Status:</strong> {cls.status}</div>
                                        
                                        {/* <div><strong>Description:</strong> {cls.class_description || "No description available"}</div> */}
                                    </div>
                                <button
                                  className="classsearch-addButton"
                                  onClick={(e) => {
                                      e.stopPropagation(); // Prevent click event from expanding/collapsing details
                                      handleAddClass(cls);
                                  }}
                                  disabled={loadingClass === cls.class_number}
                              >
                                  {loadingClass === cls.class_number ? (
                                      <ThreeDots
                                          height="10"
                                          width="24"
                                          radius="9"
                                          color="#007bff"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          visible={true}
                                      />
                                  ) : (
                                      <AddIcon />
                                  )}
                              </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>

        <div className="classsearch-trackedClassesContainer">
            {errorMessage && <div className="errorMessage">{errorMessage}</div>}
            <h2 className="classsearch-title">Tracked Classes</h2>
            
            <div className="classsearch-trackedClassList">
                {profile.tracked_classes.map((trackedClass, index) => (
                    <div
                        key={index}
                        className={`classsearch-trackedClassItem ${expandedClasses[trackedClass.class_number] ? 'expanded' : ''}`}
                        style={{ backgroundColor: stringToColor(college.color, trackedClass.class_code) }}
                        onClick={() => toggleClassDetails(trackedClass.class_number)}
                    >
                        <div className="classInfo">
                            <span><strong>Class Number:</strong> {trackedClass.class_number}</span>
                        </div>
                        <button
                            className="classsearch-removeButton"
                            onClick={(e) => {
                                e.stopPropagation(); 
                                handleRemoveClass(trackedClass);
                            }}
                            disabled={loadingClass === trackedClass.class_number}
                        >
                            {loadingClass === trackedClass.class_number ? (
                                <ThreeDots
                                    height="10"
                                    width="24"
                                    radius="9"
                                    color="#007bff"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            ) : (
                                <FaTrash />
                            )}
                        </button>
                        {expandedClasses[trackedClass.class_number] && (
                            <div className="classsearch-dropdownContent">
                                <div><strong>College ID:</strong> {trackedClass.college_id}</div>
                                <div><strong>Term:</strong> {termSlug}</div>
                                <div><strong>Class Code:</strong> {trackedClass.class_code}</div>
                                <div><strong>Course Topic:</strong> {trackedClass.course_topic}</div>
                                <div><strong>Class Component:</strong> {trackedClass.class_component}</div>
                                <div><strong>Instructor:</strong> {trackedClass.instructor}</div>
                                <div><strong>Number of People Tracking:</strong> {trackedClass.total_count}</div>
                                <div><strong>Your Overall Rank:</strong> {trackedClass.overall_rank}</div>
                                <div><strong>Your Rank Within Tier:</strong> {trackedClass.rank_within_tier + ' (' + trackedClass.membership_tier + ')'}</div>
                                <div><strong>Class Status:</strong> {trackedClass.status}</div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    </div>
);}

export default SearchPage;
