import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { ProfileProvider } from './ProfileContext'; // Import ProfileProvider
import ProtectedRoute from './ProtectedRoute';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './forgot-password';
import ResetPassword from './reset-password';
import VerifyEmail from './VerifyEmail';
import Profile from './Profile';
import Homepage from './homepage';
import SearchPage from './searchPage'; 
import TermSelectionPage from './TermSelectionPage';
import MySchedule from './MySchedule';
import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ProfileProvider> {/* Ensure that ProfileProvider wraps the components */}
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
            <Route path="/MySchedule" element={<ProtectedRoute element={<MySchedule />} />} />
            <Route path="/homepage" element={<ProtectedRoute element={<Homepage />} />} />
            <Route path="/searchPage/:collegeSlug" element={<ProtectedRoute element={<TermSelectionPage />} />} />
            <Route path="/searchPage/:collegeSlug/:termSlug" element={<ProtectedRoute element={<SearchPage />} />} />
            <Route path="/" element={<Login />} />
          </Routes>
        </ProfileProvider>
      </AuthProvider>
      </Router>
  );
}

export default App;
